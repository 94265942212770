import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import './reservationPage.css';

import ReservationSteps from './ReservationSteps';
import Login from '../auth/Login';

function ReservationPage() {
    const [isLogin, setIsLogin] = useState(false);
    const user = sessionStorage.getItem("user");
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setIsLogin(true);
            navigate('/reservation');
        } else {
            setIsLogin(false);
        }
    }, [user, navigate]);

    const [currentDate, setCurrentDate] = useState("");
    const [minDate, setMinDate] = useState("");
    const [maxDate, setMaxDate] = useState("");
    
     useEffect(() => {
        // Function to calculate the current date and the date 14 days from today
        const setDateRange = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = today.getMonth();
            const day = today.getDate();

            // Format day to ensure it's always two digits
            const formattedDay = day < 10 ? `0${day}` : day;
        
            // Set the date as 2015-03-dd format
            const date = `2015-03-${formattedDay}`;


            setCurrentDate(date); // Update the state with the formatted date

            // Get the current date (today)
            const startDate = new Date(year, month, day);

            // Calculate the date 14 days from today
            const endDate = new Date(year, month, day + 28);

            // Format the dates to YYYY-MM-DD
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];

            // Set the min and max state values
            setMinDate(formattedStartDate);
            setMaxDate(formattedEndDate);
        };

        setDateRange(); // Set the date range when the component mounts
    }, []);
      

    return (
        <main className="container text-white py-5">
            {isLogin ? (
                <>
                    <ReservationSteps />
                    <div className="mb-5">
                        <h5>Quelle date voulez-vous jouer ?</h5>
                        <div class="mb-3">
                            <input 
                                type="date" 
                                className="form-control form-control-lg"
                                value={currentDate}
                                min={minDate}
                                max={maxDate}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <h5>Choisissez une option</h5>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item w-50" role="presentation">
                                <button className="nav-link active w-100" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                                    Simulateurs
                                </button>
                            </li>
                            <li className="nav-item w-50" role="presentation">
                                <button className="nav-link w-100" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                                    Simulateur VIP
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                ...
                            </div>
                            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">...</div>
                        </div>
                    </div>
                </>
            ) : (
                <Login />
            )}
        </main>
    )
}

export default ReservationPage