import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

import './reservationSteps.css';

const ReservationSteps = ({ step1, step2, step3, step4, step5, step6, step7 }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        {label: 'Sélectionner'},
        {label: 'Identifier'},
        {label: 'Confirmer'},
        {label: 'Jouer'},
    ];

    return (
        <div className="steps__wrapper">
            <div className="steps__box">
                {
                    steps.map((step, index) => (
                        <>
                            <div key={index} className="steps__box__label">
                                <div className={`steps__box__label__block ${index <= currentStep ? 'active' : ''}`}>
                                    <div className="steps__box__label-number">
                                        {index < currentStep ? (
                                            <i className="fa-solid fa-check"></i>
                                        ) : (
                                            index + 1
                                        )}
                                    </div>
                                    <div className="steps__box__label-text">
                                        {step.label}
                                    </div>
                                </div>
                                {index < steps.length - 1 && (
                                    <div className={`steps__box__label__link ${index < currentStep ? 'active' : ""}`}>
                                    </div>
                                )}
                            </div>
                        </>
                    ))
                }
            </div>
            {/* <div className="buttons">
                <button>Previous</button>
                <button>Next</button>
            </div> */}
        </div>
        // <section className="progressBar">
        //     <div className="py-5">
        //         <ul className="nav justify-content-center">
        //             <li className="mb-3">
        //                 {step1 ? (
        //                     <NavLink className="btn btn-outline-info me-2" to='/reservation-location'>
        //                         <div className="text-white">1</div>
        //                         Endroit
        //                     </NavLink>
        //                 ) : (
        //                     <NavLink>
        //                         <div>1</div>
        //                         <div>Endroit</div>
        //                     </NavLink>
        //                 )}
        //             </li>

        //             <li className="mb-3">
        //             {step2 ? (
        //                 <NavLink className="btn btn-outline-info me-2" to='/reservation-services'>
        //                 Service
        //                 </NavLink>
        //             ) : (<NavLink className="btn btn-outline-info me-2 disabled">Service</NavLink>)}
        //             </li>

        //             {/* <li className="mb-3">
        //             {step3 ? (
        //                 <NavLink className="btn btn-outline-info me-2" to='/reservation-service'>
        //                 Panier
        //                 </NavLink>
        //             ) : (<NavLink className="btn btn-outline-info me-2 disabled">Panier</NavLink>)}
        //             </li> */}

        //             <li className="mb-3">
        //             {step3 ? (
        //                 <NavLink className="btn btn-outline-info me-2" to='/reservation-panier'>
        //                 Panier
        //                 </NavLink>
        //             ) : (<NavLink className="btn btn-outline-info me-2 disabled">Panier</NavLink>)}
        //             </li>

        //             <li className="mb-3">
        //             {step4 ? (
        //                 <NavLink className="btn btn-outline-info me-2" to='/reservation-date'>
        //                 Date
        //                 </NavLink>
        //             ) : (<NavLink className="btn btn-outline-info me-2 disabled">Date</NavLink>)}
        //             </li>

        //             <li className="mb-3">
        //             {step5 ? (
        //                 <NavLink className="btn btn-outline-info me-2" to='/reservation-client-info'>
        //                 Client Info
        //                 </NavLink>
        //             ) : (<NavLink className="btn btn-outline-info me-2 disabled">Client Info</NavLink>)}
        //             </li>

        //             <li className="mb-3">
        //             {step6 ? (
        //                 <NavLink className="btn btn-outline-info me-2" to='/reservation-summary'>
        //                 Vérifier
        //                 </NavLink>
        //             ) : (<NavLink className="btn btn-outline-info me-2 disabled">Vérifier</NavLink>)}
        //             </li>

        //             <li className="mb-3">
        //             {step7 ? (
        //                 <NavLink className="btn btn-outline-info me-2" to='/reservation-confirmation'>
        //                 Terminer
        //                 </NavLink>
        //             ) : (<NavLink className="btn btn-outline-info me-2 disabled">Terminer</NavLink>)}
        //             </li>
        //         </ul>
        //     </div>
        // </section>
    )
}

export default ReservationSteps;