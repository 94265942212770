import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

function HomeCourse() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const emailjsPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

        const templateParams = {
            name: name,
            email: email,
            message: message
        };

        emailjs.send(emailjsServiceId, emailjsTemplateId, templateParams, emailjsPublicKey)
            .then((response) => {
                console.log('Email sent successfully', response);
                setIsSubmitted(true);
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch((error) => {
                console.error('Error sending email', error);
            });
    }

    return (
        <section id="course" className="course">
            <div className="container-fluid">
                <div className="row">
                    <h4 className="section-title">COURS DE GOLF</h4>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6">
                        <h4 className="text-white mb-3">
                            <strong>J-Golf propose des cours de golf privés et semi-privés dispensés par un professeur de golf professionnel.</strong>
                        </h4>
                        <figure>
                            <blockquote className="blockquote pt-3">
                                <p className="text-white">
                                    Que vous soyez débutant ou joueur expérimenté, notre coach saura vous accompagner et vous aider à progresser à votre rythme. Pour plus d’informations et pour réserver votre séance, n’hésitez pas à nous contacter en remplissant le formulaire ci-contre ou ci-dessous.
                                </p>
                            </blockquote>
                        </figure>
                    </div>
                    <div className="col-md-6 text-white">
                        {!isSubmitted ? (
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label"><h5>Nom et prénom</h5></label>
                                    <input 
                                        type="text" 
                                        className="form-control form-control-lg" 
                                        id="name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label"><h5>Courriel</h5></label>
                                    <input
                                        type="email"
                                        className="form-control form-control-lg"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} 
                                        required 
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label"><h5>Message</h5></label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        className="form-control"  
                                        rows="5"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)} 
                                        required
                                    ></textarea>
                                </div>
                                <div className="pt-2">
                                    <button type="submit" className="btn btn-primary fs-5 fw-bold">Envoyer</button>
                                </div>
                            </form>
                        ) : (
                            <div className="text-center">
                                <h3 className="text-white">Merci pour votre message!</h3>
                                <h3>Nous vous répondrons dès que possible.</h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeCourse