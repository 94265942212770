import React from 'react';
import './homeForfait.css';

function HomeForfait() {

    return (
        <section id="forfait" className="forfait">
            <div className="container-fluid">
                <div className="row">
                    <h4 className="section-title">Forfait</h4>
                </div>
                <div className="row mt-5">
                    <h4 className="text-white mb-3">
                        <strong>Achetez votre carte de golf et profitez de nos offres !</strong>
                    </h4>
                    <figure>
                        <blockquote className="blockquote">
                            <p className="text-white">
                                Vous souhaitez jouer au golf dans un cadre unique et innovant ? Achetez dès maintenant une carte de golf et venez découvrir notre <span className="fw-bold text-white">golf virtuel intérieur</span> au <span className="fw-bold text-white">J-Golf Pyramide</span>. Grâce à notre technologie de pointe, vous pourrez jouer sur des parcours célèbres du monde entier, sans quitter l'intérieur, dans une expérience immersive et réaliste. De plus, en acquérant votre carte, vous bénéficierez d'un crédit de jeu offert, pour vous permettre de profiter pleinement de votre expérience.
                            </p>
                        </blockquote>
                    </figure>
                    <div className="col-md-6">
                        <figure>
                            <blockquote className="blockquote pt-3">
                                {/* <p className="text-white">
                                    De plus, en acquérant votre carte, vous bénéficierez d'un crédit de jeu offert, pour vous permettre de profiter pleinement de votre expérience.
                                </p> */}
                                <p className="text-white">
                                    Attention, la carte permet uniquement de régler le temps de jeu. Les boissons et autres consommations ne sont pas incluses dans cette offre.
                                </p>
                                <p className="text-white">
                                    N'attendez plus ! Venez en succursale pour acheter votre carte et commencez à jouer tout de suite. Nous vous attendons avec impatience !
                                </p>
                            </blockquote>
                        </figure>
                    </div>
                    <div className="col-md-6 text-center fw-bold pt-4">
                        <table className="table table-dark table-hover">
                            <thead>
                                <tr className="border-top">
                                    <th scope="col">Acheter</th>
                                    <th scope="col">Offert</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>500<span className="dollar-sign">$</span></td>
                                    <td>150<span className="dollar-sign">$</span></td>
                                    <td>650<span className="dollar-sign">$</span></td>
                                </tr>
                                <tr>
                                    <td>1000<span className="dollar-sign">$</span></td>
                                    <td>350<span className="dollar-sign">$</span></td>
                                    <td>1350<span className="dollar-sign">$</span></td>
                                </tr>
                                <tr className="border-bottom">
                                    <td>2000<span className="dollar-sign">$</span></td>
                                    <td>800<span className="dollar-sign">$</span></td>
                                    <td>2800<span className="dollar-sign">$</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeForfait