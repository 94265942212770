import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// import Layout from './components/layout/Layout';
import HomePage from './pages/home/HomePage';
import ReservationPage from './pages/reservations/ReservationPage';
// import Header from './pages/Header';
// import Banner from './pages/Banner';
// import Main from './pages/Main';
// import Footer from './pages/Footer';
import BackToTopBtn from './components/buttons/BackToTopBtn';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';


function App() {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY);
    });
    return () => {
      window.removeEventListener('scroll', () => {
        setScroll(window.scrollY);
      });
    };
  }, [scroll]);

  return (
    <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="reservation" element={<ReservationPage />} />
        </Routes>
        <Footer />
        <BackToTopBtn scroll={scroll} />
    </Router>
  );
}

export default App;
