import React, {useState, useEffect} from 'react';
import 'swiper/css';
import './homeBanner.css';
// import bgImg from '../images/golf-bg02.jpg';
// import ServiceContent from '../components/ServiceContent';
// import ServiceDate from '../components/ServiceDate';
import PlayButton from '../../components/PlayButton';
import ServiceSwiper from '../../components/ServiceSwiper';
const dataServicesUrl = process.env.REACT_APP_DATA_SERVICES_URL;

function Banner() {
    const [services, setServices] = useState([]);
    const [modalImage, setModalImage] = useState(null); // State to manage modal image
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    const fetchData = () => {
        fetch(dataServicesUrl)
        .then(res => res.json())
        .then(data => setServices(data))
        .catch(e => console.log(e.message));
    };

    useEffect(() => {
        fetchData()
    }, []);

    // const handleSlideChange = id => {
    //     const newServices = services.map(service => {
    //         service.active = false;
    //         if (service._id === id) {
    //             service.active = true;
    //         }
    //         return service;
    //     });
    //     setServices(newServices);
    // }

    const handleSlideShowModal = (imageSrc) => {
        setModalImage(imageSrc); // Set the clicked image source
        setShowModal(true); // Show the modal
    };

    const handleCloseModal = () => {
        setShowModal(false); // Hide the modal
    };

    return (
        <div className='banner'>
            {
                services && services.length > 0 && services.map(service => (
                    <div className='service' key={service._id}>
                        <img src={service.bgImg} className={`bgImg ${service.active ? 'active' : undefined}`} alt={service.title} />
                        <div className='container-fluid'>
                            <div className='playButtonWrapper'>
                                <PlayButton service={service} />
                                {/* <div className='col-lg-6 col-md-12'>
                                <ServiceContent service={service} />
                                </div> */}
                                {/* <div className='col-lg-6 col-md-12'>
                                <ServiceDate service={service} />
                                <PlayButton service={service} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                ))
            }
            
            {services && services.length > 0 && (
                // <ServiceSwiper slides={services} slideChange={handleSlideChange} />
                <ServiceSwiper slides={services} slideShowModal={handleSlideShowModal} /> 
            )}

            {showModal && (
                <div className="modal fade show" style={{ display: 'block' }} role="dialog" aria-labelledby="imageModalLabel" aria-hidden="false">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="imageModalLabel">Image Preview</h5>
                                <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close">
                                    <span aria-hidden="true"></span>
                                </button>
                            </div> */}
                            <div className="modal-body">
                                <img src={modalImage} className="d-block w-100" alt="Rabais - Promotion" />
                            </div>
                            <div className="modal-footer">
                                <button 
                                    type="button" 
                                    className="btn btn-primary btn-lg fw-bold text-uppercase w-100" 
                                    onClick={handleCloseModal}
                                >
                                    Fermer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Banner